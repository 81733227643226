import { useEffect, useMemo, useState } from "react";

import posthog from "posthog-js";
import { Bookmark } from "tabler-icons-react";

import {
  ActionIcon,
  Badge,
  Card,
  Group,
  Image,
  Loader,
  Text,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { AlgoliaHit, OnboardedCustomer, supabase } from "../constants";
import { useUser } from "./provider";
import { secondsToFormattedTime } from "./ResultCard";

export interface BookmarkCardProps {
  bookmark: Omit<AlgoliaHit, "_highlightResult">;
  customer: OnboardedCustomer;
}

export function BookmarkCard({ bookmark, customer }: BookmarkCardProps) {
  const videoId = bookmark.videoUrl.split("v=")[1];
  const [title, setTitle] = useState("");
  const { user, bookmarks, refreshBookmarks } = useUser();
  const isBookmarked = useMemo(
    () =>
      bookmarks.find((bm) => bm.objectId === bookmark.objectID) !== undefined,
    [bookmarks, bookmark.objectID]
  );

  useEffect(() => {
    fetch(`https://noembed.com/embed?url=${bookmark.videoUrl}`)
      .then((response) => response.json())
      .then((data) => setTitle(data.title));
  });

  return (
    <a
      href={`${bookmark.videoUrl}&t=${bookmark.start}`}
      target="_blank"
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      onClick={() => {
        posthog.capture("bookmark_clicked", {
          video_id: bookmark.objectID,
          video_title: title,
          video_start: bookmark.start,
          video_end: bookmark.end,
          customer: customer.index,
        });
      }}
    >
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        pos="relative"
        style={{ height: "100%" }}
      >
        <Card.Section>
          <Image
            src={`http://img.youtube.com/vi/${videoId}/0.jpg`}
            height={200}
            alt="Thumbnail"
          />
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          {title ? (
            <Text
              fw={500}
              flex={1}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Text>
          ) : (
            <Loader />
          )}
          <Badge color="blue">{secondsToFormattedTime(bookmark.start)}</Badge>

          {user && (
            <ActionIcon
              variant="subtle"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (user) {
                  if (isBookmarked) {
                    supabase
                      .from("bookmarks")
                      .delete()
                      .eq("objectId", bookmark.objectID)
                      .eq("user", user.id)
                      .then(() => {
                        refreshBookmarks();
                        showNotification({
                          title: "Success!",
                          color: "green",
                          message: "Video unbookmarked.",
                        });
                      });
                  } else {
                    supabase
                      .from("bookmarks")
                      .upsert({
                        objectId: bookmark.objectID,
                        index: customer.index,
                        user: user.id,
                      })
                      .then(() => {
                        refreshBookmarks();
                        showNotification({
                          title: "Success!",
                          color: "green",
                          message: "Video bookmarked.",
                        });
                      });
                  }
                }
              }}
            >
              <Bookmark fill={isBookmarked ? "#329AEF" : "transparent"} />
            </ActionIcon>
          )}
        </Group>

        <Text>{bookmark.text}</Text>
      </Card>
    </a>
  );
}
