import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useLocalStorage } from "@mantine/hooks";
import { User } from "@supabase/supabase-js";

import { Database } from "../../supabase/database";
import { Customers, OnboardedCustomer, supabase } from "../constants";

const PodcastContext = createContext<{
  customer: OnboardedCustomer | null;
  setCustomer: (customer: string) => void;
}>({
  customer: null,
  setCustomer: () => {},
});

export const usePodcast = () => useContext(PodcastContext);

export function PodcastProvider({ children }: { children: ReactNode }) {
  const params = new URLSearchParams(window.location.search);
  const query = params.get("q") || "";

  const [customer, setCustomer] = useLocalStorage<string>({
    key: "poddlo-customer",
    defaultValue: query || decodeURIComponent(query) || "the-basement-yard",
  });

  useEffect(() => {
    if (query) {
      setCustomer(query);
    }
  }, [query, setCustomer]);

  return (
    <PodcastContext.Provider
      value={{
        customer: Customers[customer],
        setCustomer: (v) => setCustomer(v),
      }}
    >
      {children}
    </PodcastContext.Provider>
  );
}

export const UserContext = createContext<{
  user: User | null;
  bookmarks: Database["public"]["Tables"]["bookmarks"]["Row"][];
  refreshBookmarks: () => void;
}>({
  user: null,
  bookmarks: [],
  refreshBookmarks: () => {},
});
export const useUser = () => useContext(UserContext);
export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [bookmarks, setBookmarks] = useState<
    Database["public"]["Tables"]["bookmarks"]["Row"][]
  >([]);

  function refreshBookmarks() {
    supabase
      .from("bookmarks")
      .select("*")
      .then(({ data }) => {
        console.log({ data });
        setBookmarks(data || []);
      });
  }

  useEffect(() => {
    refreshBookmarks();
  }, []);

  useEffect(() => {
    supabase.auth.getUser().then((user) => {
      setUser(user.data.user ?? null);
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, bookmarks, refreshBookmarks }}>
      {children}
    </UserContext.Provider>
  );
}
