import { useEffect, useMemo, useState } from "react";

import posthog from "posthog-js";
import { Bookmark } from "tabler-icons-react";

import {
  ActionIcon,
  Badge,
  Card,
  Group,
  Image,
  Skeleton,
  Text,
  Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";

import { AlgoliaHit, OnboardedCustomer, supabase } from "../constants";
import { useUser } from "./provider";

// Convert seconds to formatted time: hh:mm:ss
export function secondsToFormattedTime(seconds: string) {
  const hours = Math.floor(parseInt(seconds, 10) / 3600);
  const minutes = Math.floor((parseInt(seconds, 10) % 3600) / 60);
  const remainingSeconds = parseInt(seconds, 10) % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
}

export interface ResultCardProps {
  hit: AlgoliaHit;
  customer: OnboardedCustomer;
}

export function ResultCard({ hit, customer }: ResultCardProps) {
  const videoId = hit.videoUrl.split("v=")[1];
  const [title, setTitle] = useState("");
  const { user, bookmarks, refreshBookmarks } = useUser();
  const { hovered, ref } = useHover<HTMLAnchorElement>();
  const isBookmarked = useMemo(
    () => bookmarks.find((bm) => bm.objectId === hit.objectID) !== undefined,
    [bookmarks, hit.objectID]
  );

  useEffect(() => {
    fetch(`https://noembed.com/embed?url=${hit.videoUrl}`)
      .then((response) => response.json())
      .then((data) => setTitle(data.title));
  });

  return (
    <a
      href={`${hit.videoUrl}&t=${hit.start}`}
      target="_blank"
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      ref={ref}
      onClick={() => {
        posthog.capture("video_clicked", {
          video_id: hit.objectID,
          video_title: title,
          video_start: hit.start,
          video_end: hit.end,
          customer: customer.index,
        });
      }}
    >
      <Tooltip label={title}>
        <Card
          shadow={hovered ? "xl" : "sm"}
          padding="lg"
          radius="md"
          withBorder
          style={{ height: "100%", transition: "300ms" }}
        >
          <Card.Section>
            <Image
              src={`http://img.youtube.com/vi/${videoId}/0.jpg`}
              height={200}
              alt="Thumbnail"
            />
          </Card.Section>

          <Group justify="space-between" gap={8} mt="md" mb="xs">
            {title ? (
              <Text
                fw={500}
                flex={1}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </Text>
            ) : (
              <Skeleton flex="1" height={20} />
            )}
            <Badge color="blue" variant="outline" radius={4}>
              {secondsToFormattedTime(hit.start)}
            </Badge>
            {user && (
              <ActionIcon
                variant="subtle"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (user) {
                    if (isBookmarked) {
                      supabase
                        .from("bookmarks")
                        .delete()
                        .eq("objectId", hit.objectID)
                        .eq("user", user.id)
                        .then(() => {
                          refreshBookmarks();
                          showNotification({
                            title: "Success!",
                            color: "green",
                            message: "Video unbookmarked.",
                          });
                        });
                    } else {
                      supabase
                        .from("bookmarks")
                        .upsert({
                          objectId: hit.objectID,
                          index: customer.index,
                          user: user.id,
                        })
                        .then(() => {
                          refreshBookmarks();
                          showNotification({
                            title: "Success!",
                            color: "green",
                            message: "Video bookmarked.",
                          });
                        });
                    }
                  }
                }}
              >
                <Bookmark fill={isBookmarked ? "#329AEF" : "transparent"} />
              </ActionIcon>
            )}
          </Group>

          <Text
            dangerouslySetInnerHTML={{
              __html: hit._highlightResult.text.value,
            }}
          />
        </Card>
      </Tooltip>
    </a>
  );
}
