import { useEffect, useMemo, useState } from "react";

import posthog from "posthog-js";
import { Check, Link } from "tabler-icons-react";

import {
  ActionIcon,
  CopyButton,
  Group,
  Pagination,
  Select,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";

import { EmptyState } from "../components/EmptyState";
import { Layout } from "../components/Layout";
import { usePodcast } from "../components/provider";
import { ResultCard } from "../components/ResultCard";
import { AlgoliaHit, Customers, searchClient } from "../constants";

const PAGE_SIZE = 50;

export function SearchPage() {
  const [searchTerm, setSearchTerm] = useDebouncedState("", 400);
  const [numResults, setNumResults] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hits, setHits] = useState<AlgoliaHit[]>([]);

  const { customer, setCustomer } = usePodcast();

  // Load search term and page from URL.
  useEffect(() => {
    const url = new URL(window.location.href);
    const s = url.searchParams.get("s");
    const p = url.searchParams.get("p");

    console.log({ s, p });
    if (s) {
      setSearchTerm(s);
    }

    if (p) {
      setPage(parseInt(p));
    }
  }, [window.location.href]);

  // Fetch search results.
  useEffect(() => {
    customer &&
      searchClient
        .initIndex(customer.index)
        .search<AlgoliaHit>(searchTerm, {
          page: page - 1,
          hitsPerPage: PAGE_SIZE,
        })
        .then((data) => {
          if (customer.index && searchTerm) {
            posthog.capture("search", {
              searchTerm,
              customer: customer.index,
              numResults: data.nbHits,
            });
          }
          setTotalPages(data.nbPages);
          setHits(data.hits);
          setPage(data.page + 1);
          setNumResults(data.nbHits);
        });
  }, [searchTerm, page]);

  const copyValue = useMemo(() => {
    if (!customer) {
      return "";
    }
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?s=${encodeURI(searchTerm)}&q=${encodeURI(customer.index)}&p=${page}`
    );
  }, [searchTerm, customer, page]);

  if (!customer) {
    return (
      <Stack justify="center" align="center" h="100vh">
        <Select
          label="Pick a Pod"
          w="fit-content"
          placeholder="Pick a Pod"
          data={Object.values(Customers).map((c) => ({
            value: c.index,
            label: c.title,
          }))}
          searchable
          onChange={(value) => value && setCustomer(value)}
        />
      </Stack>
    );
  }

  return (
    <Layout>
      <Stack>
        <Group justify="space-between">
          <Stack gap={2}>
            <Title order={1}>Search</Title>
            <Text>{numResults.toLocaleString()} results</Text>
          </Stack>

          {searchTerm.length > 0 && totalPages > 1 && (
            <Pagination value={page} onChange={setPage} total={totalPages} />
          )}

          <Select
            w="fit-content"
            placeholder="Pick a Pod"
            value={customer.index}
            searchable
            data={Object.values(Customers).map((c) => ({
              value: c.index,
              label: c.title,
            }))}
            onChange={(v) => v && setCustomer(v)}
          />
        </Group>
        <Group>
          <TextInput
            placeholder="Search"
            defaultValue={searchTerm}
            onChange={(event) => setSearchTerm(event.currentTarget.value)}
            style={{ flex: 1 }}
          />
          <Tooltip label="Share a link to this search">
            <CopyButton value={copyValue}>
              {({ copied, copy }) => (
                <ActionIcon
                  variant="light"
                  onClick={() => {
                    copy();
                    showNotification({
                      title: "Success!",
                      color: "green",
                      message: "Link copied.",
                    });
                  }}
                >
                  {copied ? (
                    <Check size={16} color="green" />
                  ) : (
                    <Link size={16} />
                  )}
                </ActionIcon>
              )}
            </CopyButton>
          </Tooltip>
        </Group>
      </Stack>

      {hits.length === 0 && searchTerm.length > 1 && (
        <EmptyState
          header="Sorry, we didn't find anything!"
          description="Try searching for something broader."
        />
      )}

      {searchTerm.length === 0 && (
        <EmptyState
          header="Start typing to search"
          description="We'll crawl through the podcast moments for you."
        />
      )}

      <SimpleGrid
        cols={{
          xs: 1,
          md: 2,
          lg: 3,
        }}
        spacing="md"
      >
        {searchTerm &&
          hits
            .filter((h) => h.videoUrl)
            .map((hit) => (
              <ResultCard key={hit.objectID} hit={hit} customer={customer} />
            ))}
        {/* {hits.length === 0 && (
          <Card p="lg" radius="md" shadow="sm">
            <Text>No results found</Text>
          </Card>
        )}
        {searchTerm.length === 0 && (
          <Card p="lg" radius="md" shadow="sm">
            <Text>Start typing to search</Text>
          </Card>
        )} */}
      </SimpleGrid>
    </Layout>
  );
}
