import { ReactNode } from "react";

import posthog from "posthog-js";
import { useLocation, useNavigate } from "react-router-dom";
import { Bookmark, Heart, Search, Settings } from "tabler-icons-react";

import {
  ActionIcon,
  Affix,
  AppShell,
  Button,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { supabase } from "../constants";
import { usePodcast, useUser } from "./provider";

export function Layout({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { customer } = usePodcast();
  const { user } = useUser();
  const isSmall = useMediaQuery("(min-width: 768px)");
  document.title = `Poddlo: ${customer?.title || "Podcast"} Search`;

  return (
    <AppShell
      header={{
        height: isSmall ? 80 : 150,
      }}
      padding="md"
    >
      <AppShell.Header>
        <Group
          align="center"
          w="100%"
          h="100%"
          p="md"
          justify="space-between"
          py="sm"
        >
          <Group>
            <ActionIcon
              variant={location.pathname === "/" ? "primary" : "default"}
              size="lg"
              onClick={() => navigate("/")}
            >
              <Search size={18} />
            </ActionIcon>
            <Text fz="h1" fw={500}>
              Poddlo
            </Text>
          </Group>

          {user && (
            <Group>
              <ActionIcon
                variant={
                  location.pathname === "/bookmarks" ? "primary" : "light"
                }
                onClick={() => navigate("/bookmarks")}
              >
                <Bookmark size={16} />
              </ActionIcon>

              <ActionIcon
                variant={
                  location.pathname === "/settings" ? "primary" : "light"
                }
                onClick={() => navigate("/settings")}
              >
                <Settings size={16} />
              </ActionIcon>
            </Group>
          )}

          {!user && (
            <Button
              onClick={() => {
                posthog.capture("click_sign_in_with_google");
                supabase.auth.signInWithOAuth({
                  provider: "google",
                  options: {
                    redirectTo: window.location.href,
                  },
                });
              }}
            >
              Sign In With Google
            </Button>
          )}
        </Group>
      </AppShell.Header>

      <AppShell.Main>
        <Stack
          px={{
            xs: 10,
            lg: 100,
          }}
          py="xl"
          gap="xl"
        >
          {children}
        </Stack>
      </AppShell.Main>

      <Affix bottom={20} right={20}>
        <Button
          component="a"
          href="https://buymeacoffee.com/bryanhoulton"
          target="_blank"
          bg="#ffdd03"
          c="dark"
          leftSection={<Heart fill="red" />}
          onClick={() => posthog.capture("click_buy_me_a_coffee")}
        >
          Support Poddlo!
        </Button>
      </Affix>
    </AppShell>
  );
}
