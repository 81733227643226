import { Card, Stack, Text, Title } from "@mantine/core";

export interface EmptyStateProps {
  header: string;
  description: string;
}

export function EmptyState({ header, description }: EmptyStateProps) {
  return (
    <Card withBorder>
      <Stack justify="center" style={{ minHeight: "50vh" }} align="center">
        <Title order={3}>{header}</Title>
        <Text>{description}</Text>
      </Stack>
    </Card>
  );
}
