import { Button, Select, Title, useMantineColorScheme } from "@mantine/core";

import { Layout } from "../components/Layout";
import { supabase } from "../constants";

export function SettingsPage() {
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  return (
    <Layout>
      <Title order={1}>Settings</Title>
      <Select
        label="Color Scheme"
        value={colorScheme}
        onChange={(v) => v && setColorScheme(v as "light" | "dark")}
        data={[
          { value: "light", label: "Light" },
          { value: "dark", label: "Dark" },
        ]}
      />
      <Button
        variant="light"
        color="red"
        onClick={() =>
          supabase.auth.signOut().then(() => window.location.reload())
        }
      >
        Logout
      </Button>
    </Layout>
  );
}
