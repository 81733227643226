import { useEffect, useState } from "react";

import { Group, Select, SimpleGrid, Stack, Title } from "@mantine/core";

import { BookmarkCard } from "../components/BookmarkCard";
import { EmptyState } from "../components/EmptyState";
import { Layout } from "../components/Layout";
import { usePodcast, useUser } from "../components/provider";
import { AlgoliaHit, Customers, searchClient } from "../constants";

const PAGE_SIZE = 50;

export function BookmarksPage() {
  const { customer, setCustomer } = usePodcast();
  const [hits, setHits] = useState<Omit<AlgoliaHit, "_highlightResult">[]>([]);
  const { bookmarks } = useUser();

  useEffect(() => {
    const filtered = bookmarks.filter((b) => b.index === customer?.index);
    if (!customer) {
      return;
    }

    if (filtered.length === 0 && bookmarks.length > 0) {
      setHits([]);
      const newCustomer = Object.values(Customers).find(
        (v) => v.index === bookmarks[0].index
      );
      if (newCustomer) setCustomer(newCustomer.index);
      return;
    }

    searchClient
      .initIndex(customer.index)
      .getObjects(filtered.map((b) => b.objectId))
      .then((v) => setHits(v.results as any));
  }, [bookmarks, customer]);

  const customersWithBookmarks = bookmarks
    .map((b) => b.index)
    .filter((v, i, a) => a.indexOf(v) === i);

  return (
    <Layout>
      <Stack>
        <Group justify="space-between">
          <Title order={1}>Bookmarks</Title>

          {bookmarks.length > 0 && (
            <Select
              w="fit-content"
              placeholder="Pick a Pod"
              value={customer?.index || null}
              searchable
              data={customersWithBookmarks.map((c) => ({
                value: c,
                label:
                  Object.values(Customers).find((v) => v.index === c)?.title ||
                  "",
              }))}
              onChange={(v) => v && setCustomer(v)}
            />
          )}
        </Group>

        {hits.length === 0 && (
          <EmptyState
            header="No bookmarks found."
            description="Search for a moment and bookmark it to see it here."
          />
        )}
      </Stack>

      {customer && (
        <SimpleGrid
          cols={{
            xs: 1,
            md: 2,
            lg: 3,
          }}
          spacing="md"
        >
          {hits
            .filter((h) => h.videoUrl)
            .map((hit) => (
              <BookmarkCard
                key={hit.objectID}
                bookmark={hit}
                customer={customer}
              />
            ))}
        </SimpleGrid>
      )}
    </Layout>
  );
}
