import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "./index.css";

import React from "react";

import posthog from "posthog-js";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import { PodcastProvider, UserProvider } from "./components/provider";
import { BookmarksPage } from "./pages/Bookmarks.page";
import { SearchPage } from "./pages/Search.page";
import { SettingsPage } from "./pages/Settings.page";
import reportWebVitals from "./reportWebVitals";

posthog.init("phc_RQu5BGVKhfonpF7Ur6F2zRI8Yp7QFAqaitzGfEQPv1f", {
  api_host: "https://d3nk0c1kx1c6s3.cloudfront.net",
  ui_host: "https://us.i.posthog.com",
});

const theme = createTheme({
  /** Put your mantine theme override here */
  fontFamily: "Inter, sans-serif",
  headings: {
    fontFamily: "Inter, sans-serif",
    fontWeight: "400",
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <SearchPage />,
  },
  {
    path: "/bookmarks",
    element: <BookmarksPage />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <script src="https://accounts.google.com/gsi/client" async></script>

    <MantineProvider theme={theme} defaultColorScheme="light">
      <Notifications />
      <PodcastProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </PodcastProvider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
